<script>
export default {
  name: 'retailer-product-flash',
  props: {
    name: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
}
</script>

<template>
  <div class="text-center">
    <h2
      class="m-0 mb-5 text-2xl font-normal leading-tight text-default md:text-3xl"
    >
      <b>{{ name }}</b>
    </h2>
    <img class="m-auto mt-5 block max-w-full" :src="src" :alt="name" />
  </div>
</template>
